import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Console from 'console'

require('@/assets/app.css')

require('@/assets/iframeResizer.contentWindow.min.js')

// global settings
Vue.prototype.$axios = axios
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest' // setting the header globally for all ajax requests

Vue.prototype.$Console = Console
// Vue.prototype.$siteURL = 'http://aframarklocal.com/api/'
// Vue.prototype.$siteURL = 'https://dev.aframark.com/api/'
// Vue.prototype.$siteURL = 'https://sandbox.aframark.com/api/'
Vue.prototype.$siteURL = 'https://my.aframark.com/api/'

Vue.config.productionTip = false

new Vue({
  router,
  data () {
    return {
      widgetStyles: {
        font: 'Default',
        font_size: 'Medium',
        background_color: 'fff',
        text_color: '111',
        primary_color: '3a84df',
        secondary_color: 'bbc2c6',
        badge_hide_at: false
      },
      token: this.$route.params.token
    }
  },
  computed: {
    bgColor: function () {
      return {
        'background-color': '#' + this.widgetStyles.background_color
      }
    },
    sizeH1: function () {
      let size = ''
      if (this.widgetStyles.font_size === 'Small') {
        size = '1.7rem'
      }

      if (this.widgetStyles.font_size === 'Large') {
        size = '2.1rem'
      }

      return {
        'font-size': size
      }
    },
    sizeSmall: function () {
      let size = ''
      if (this.widgetStyles.font_size === 'Small') {
        size = '11px'
      }

      if (this.widgetStyles.font_size === 'Large') {
        size = '14px'
      }

      return {
        'font-size': size
      }
    },
    sizeBody: function () {
      let size = ''
      if (this.widgetStyles.font_size === 'Small') {
        size = '0.8575rem'
      }

      if (this.widgetStyles.font_size === 'Large') {
        size = '1rem'
      }

      return {
        'font-size': size
      }
    },
    sizeLink: function () {
      let size = ''
      if (this.widgetStyles.font_size === 'Small') {
        size = '13px'
      }

      if (this.widgetStyles.font_size === 'Large') {
        size = '15px'
      }

      return {
        'font-size': size
      }
    },
    sizeLabel: function () {
      let size = ''
      if (this.widgetStyles.font_size === 'Small') {
        size = '14px'
      }

      if (this.widgetStyles.font_size === 'Large') {
        size = '16px'
      }

      return {
        'font-size': size
      }
    },
    textColor: function () {
      return {
        color: '#' + this.widgetStyles.text_color
      }
    },
    primaryColor: function () {
      return {
        color: '#' + this.widgetStyles.primary_color
      }
    },
    primaryColorButton: function () {
      let primaryColor = '#' + this.widgetStyles.primary_color
      return {
        color: primaryColor,
        'border-color': primaryColor,
        'background-color': '#' + this.widgetStyles.background_color
      }
    },
    displayBadge: function () {
      let showBadge = true
      if (this.widgetStyles.badge_hide_at) {
        showBadge = false
      }
      return showBadge
    },
    searchBar: function () {
      let size = ''
      if (this.widgetStyles.font_size === 'Small') {
        size = '0.9rem'
      }

      if (this.widgetStyles.font_size === 'Large') {
        size = '1.1rem'
      }

      return {
        color: '#' + this.widgetStyles.text_color,
        'border-color': '#' + this.widgetStyles.secondary_color,
        'background-color': '#' + this.widgetStyles.background_color,
        'font-size': size
      }
    },
    activePage: function () {
      return {
        'background-color': '#' + this.widgetStyles.primary_color
      }
    }
  },
  mounted () {
    // if (this.widgetStyles.font === 'Small') {
    //   this.fontSize = '.8rem'
    // }
    // if (this.widgetStyles.font === 'Large') {
    //   this.fontSize = '1.2rem'
    // }
  },
  render: (h) => h(App)
}).$mount('#app')
